
import {
    Corporation,
    corporationApi,
    CorporationMember,
    CorporationSearchOrder,
    CorporationSearchRequest,
    CorporationStatusSerdar,
    CorporationStatusSoner,
    CorporationStatusTaner,
} from "@/api/corporation";
import { FederalState, federalStateApi } from "@/api/federalstate";
import DataTable from "@/app/components/DataTable.vue";
import DatePicker from "@/app/components/DatePicker.vue";
import EnumField from "@/app/components/EnumField.vue";
import NumberField from "@/app/components/NumberField.vue";
import { integer, validate } from "@/app/validation";
import { configStore } from "@/store/config";
import { now } from "@/store/now";
import { getAge, getDate } from "@/util/dateTimeUtils";
import { SelectOption } from "@/util/types";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

const ITEMS_PER_PAGE = 100;

export default Vue.extend({
    data() {
        return {
            CorporationStatusSerdar,
            CorporationStatusSoner,
            CorporationStatusTaner,
            federalStates: [] as FederalState[],
            items: [] as Corporation[],
            loading: true,
            paging: {
                page: 1,
                pageSize: ITEMS_PER_PAGE,
                totalSize: 0,
                maxPage: 10_000 / ITEMS_PER_PAGE,
            },
            searchCounter: 1,
            positiveIntegerRules: integer(0),
            currentDate: getDate(now(), configStore.configuration.timezone),
            federalStateIds: [] as string[],
            registeredFrom: null as string | null,
            registeredTo: null as string | null,
            nameQuery: "",
            descriptionQuery: "software* plattform* saas app* online* digital* internet* web* marktplatz*",
            yearOfBirthRangeFrom: 1992 as number | null,
            yearOfBirthRangeTo: null as number | null,
            shareCapitalFrom: null as number | null,
            shareCapitalTo: null as number | null,
            statusesSerdar: [] as CorporationStatusSerdar[],
            statusesSoner: [] as CorporationStatusSoner[],
            statusesTaner: [] as CorporationStatusTaner[],
            order: CorporationSearchOrder.PUBLISHED_DESC,
        };
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t("Status"),
                    width: "1%",
                },
                {
                    text: this.$t("Stammdaten"),
                    width: "25%",
                },
                {
                    text: this.$t("Leitung"),
                    width: "25%",
                },
                {
                    text: this.$t("Unternehmensgegenstand"),
                    width: "49%",
                },
            ] as DataTableHeader[];
        },

        federalStateOptions(): SelectOption[] {
            return this.federalStates.map((f) => ({
                value: f.id,
                text: this.$t(`enum.FederalStateId.${f.id}`),
            }));
        },
    },

    methods: {
        async setFilter2020TanerUnknown() {
            this.federalStateIds = [];
            this.registeredFrom = "2022-01-01";
            this.registeredTo = "2022-12-31";
            this.nameQuery = "";
            this.descriptionQuery = "";
            this.yearOfBirthRangeFrom = 1994;
            this.yearOfBirthRangeTo = null;
            this.shareCapitalFrom = null;
            this.shareCapitalTo = null;
            this.statusesSerdar = [];
            this.statusesSoner = [];
            this.statusesTaner = [CorporationStatusTaner.UNKNOWN, CorporationStatusTaner.CHECK_LATER];
            this.order = CorporationSearchOrder.PUBLISHED_DESC;
            await this.reload();
        },

        async updateStatusSerdar(item: Corporation, status: CorporationStatusSerdar) {
            await corporationApi.updateStatusSerdar(item.id, status);
            item.statusSerdar = status;
        },

        async updateStatusSoner(item: Corporation, status: CorporationStatusSoner) {
            await corporationApi.updateStatusSoner(item.id, status);
            item.statusSoner = status;
        },

        async updateStatusTaner(item: Corporation, status: CorporationStatusTaner) {
            await corporationApi.updateStatusTaner(item.id, status);
            item.statusTaner = status;
        },

        getStatusButtonColorSerdar(status: CorporationStatusSerdar) {
            if (status === CorporationStatusSerdar.UNKNOWN) {
                return "grey";
            }
        },

        getStatusButtonColorSoner(status: CorporationStatusSoner) {
            if (status === CorporationStatusSoner.UNKNOWN) {
                return "grey";
            }
            if (status === CorporationStatusSoner.REJECTED) {
                return "error";
            }
            if (status === CorporationStatusSoner.POTENTIAL) {
                return "warning";
            }
            if (status === CorporationStatusSoner.CRM) {
                return "success";
            }
            if (status === CorporationStatusSoner.FL_POTENTIAL) {
                return "warning";
            }
            if (status === CorporationStatusSoner.FL_CRM) {
                return "success";
            }
        },

        getStatusButtonColorTaner(status: CorporationStatusTaner) {
            if (status === CorporationStatusTaner.UNKNOWN) {
                return "grey";
            }
            if (status === CorporationStatusTaner.USELESS) {
                return "error";
            }
            if (status === CorporationStatusTaner.GOOD_IDEA) {
                return "green lighten-2";
            }
            if (status === CorporationStatusTaner.EXCELLENT_IDEA) {
                return "green darken-2";
            }
            if (status === CorporationStatusTaner.CHECK_LATER) {
                return "blue lighten-1";
            }
        },

        async reset() {
            this.federalStateIds = [];
            this.registeredFrom = null;
            this.registeredTo = null;
            this.nameQuery = "";
            this.descriptionQuery = "";
            this.yearOfBirthRangeFrom = null;
            this.yearOfBirthRangeTo = null;
            this.shareCapitalFrom = null;
            this.shareCapitalTo = null;
            this.statusesSerdar = [];
            this.statusesSoner = [];
            this.statusesTaner = [];
            this.order = CorporationSearchOrder.PUBLISHED_DESC;
            await this.reload();
        },

        async reload() {
            this.paging.page = 1;
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        getFullName(item: CorporationMember) {
            return `${item.givenName} ${item.familyName}`;
        },

        getFullAddress(item: Corporation) {
            return `${item.street} ${item.houseNumber}, ${item.zip} ${item.addressCity}`;
        },

        getGoogleSearchLink(query: string) {
            query = query.replace("(haftungsbeschränkt)", "").trim();
            return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
        },

        getGoogleMapsLink(query: string) {
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`;
        },

        getAge(localDate: string) {
            return getAge(new Date(), localDate, configStore.configuration.timezone);
        },

        async loadItems() {
            this.paging.totalSize = 0;
            this.items = [];
            this.loading = true;
            try {
                const searchRequest: CorporationSearchRequest = {
                    federalStateIds: this.federalStateIds,
                    registeredFrom: this.registeredFrom,
                    registeredTo: this.registeredTo,
                    nameQuery: this.nameQuery,
                    descriptionQuery: this.descriptionQuery,
                    yearOfBirthRangeFrom: validate(this.positiveIntegerRules, this.yearOfBirthRangeFrom)
                        ? this.yearOfBirthRangeFrom
                        : null,
                    yearOfBirthRangeTo: validate(this.positiveIntegerRules, this.yearOfBirthRangeTo)
                        ? this.yearOfBirthRangeTo
                        : null,
                    shareCapitalFrom: validate(this.positiveIntegerRules, this.shareCapitalFrom)
                        ? this.shareCapitalFrom
                        : null,
                    shareCapitalTo: validate(this.positiveIntegerRules, this.shareCapitalTo)
                        ? this.shareCapitalTo
                        : null,
                    statusesSerdar: this.statusesSerdar,
                    statusesSoner: this.statusesSoner,
                    statusesTaner: this.statusesTaner,
                    order: this.order,
                    start: (this.paging.page - 1) * ITEMS_PER_PAGE,
                    size: ITEMS_PER_PAGE,
                    searchId: ++this.searchCounter,
                };

                const result = await corporationApi.search(searchRequest);

                if (result.searchId === this.searchCounter) {
                    this.items = result.corporations;
                    this.paging.totalSize = result.totalSize;
                    this.loading = false;
                }
            } catch (e) {
                this.loading = false;
                this.paging.page = 1;
                throw e;
            }
        },
    },

    async mounted() {
        this.federalStates = await federalStateApi.getAll();
        await this.loadItems();
    },

    components: {
        DataTable,
        DatePicker,
        NumberField,
        EnumField,
    },
});
