
import { snackbar } from "@/store/snackbar";
import Vue from "vue";

export default Vue.extend({
    computed: {
        snackbar() {
            return snackbar;
        },
    },
});
