
import { getFirstDayOfWeek } from "@/util/dateTimeUtils";
import Vue from "vue";

export default Vue.extend({
    props: {
        value: {
            type: String,
            required: false,
        },
        mindate: {
            type: String,
            required: false,
        },
        maxdate: {
            type: String,
            required: false,
        },
        rules: {
            type: Array as () => Function[] | undefined,
            required: false,
        },
    },

    data() {
        return {
            menu: false,
            date: this.value as string | null,
        };
    },

    computed: {
        firstDayOfWeek() {
            return getFirstDayOfWeek(this.$i18n.locale);
        },
        rulez(): Function[] {
            const rs = this.rules || [];
            return rs.map((r) => () => r(this.date));
        },
    },

    methods: {
        handleInput() {
            this.$emit("input", this.date);
        },
        clear() {
            this.date = null;
            this.$emit("change", this.date);
            this.handleInput();
        },
    },

    watch: {
        date(value: string) {
            (this.$refs.menu as any).save(value);
        },
        value(value: string) {
            this.date = value;
        },
    },
});
