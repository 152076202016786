import { reactive } from "@/util/reactive";

@reactive
class GlobalDialog {
    title = "";
    text = "";
    okLabel: string | null = null;
    cancelLabel: string | null = null;
    visible = false;
    confirm = false;
    private resolve?: (result: boolean) => void;

    async showAlert(title: string, text: string, okLabel?: string) {
        await this.show(false, title, text, okLabel);
    }

    showConfirm(title: string, text: string, okLabel?: string, cancelLabel?: string) {
        return this.show(true, title, text, okLabel, cancelLabel);
    }

    private show(confirm: boolean, title: string, text: string, okLabel?: string, cancelLabel?: string) {
        if (this.resolve) {
            this.resolve(false);
        }
        this.title = title;
        this.text = text;
        this.okLabel = okLabel || null;
        this.cancelLabel = cancelLabel || null;
        this.visible = true;
        this.confirm = confirm;
        return new Promise<boolean>((resolve) => {
            this.resolve = resolve;
        });
    }

    hide(result: boolean) {
        this.visible = false;
        if (this.resolve) {
            this.resolve(result);
            this.resolve = undefined;
        }
    }
}

export const globalDialog = new GlobalDialog();
