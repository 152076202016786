
import Vue from "vue";

export default Vue.extend({
    props: {
        paging: {
            type: Object,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
    },
});
