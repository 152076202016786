
import { globalDialog } from "@/store/globalDialog";
import Vue from "vue";

export default Vue.extend({
    computed: {
        globalDialog() {
            return globalDialog;
        },
    },
});
