import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface RegisterCourt {
    readonly id: string;
    readonly federalStateId: string;
    readonly name: string;
}

interface RegisterCourtApi {
    getAll(): Promise<RegisterCourt[]>;
}

export const registerCourtApi: RegisterCourtApi = {
    async getAll() {
        return cloneObject((await axios.get("/api/register-court")).data);
    },
};
