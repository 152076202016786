
import { showError } from "@/app/messageUtil";
import { notEmpty } from "@/app/validation";
import { configStore } from "@/store/config";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            password: "",
            passwordRules: notEmpty().msg(() => this.$t("Ein Passwort ist erforderlich.")),
            loading: false,
        };
    },

    computed: {
        shortCommitId() {
            return configStore.configuration.shortCommitId;
        },

        buildDate() {
            return configStore.configuration.buildTime.toISOString().substring(0, 10);
        },
    },

    methods: {
        async login() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            this.loading = true;
            try {
                if (await userSession.login(this.password)) {
                    await this.$router.replace((this.$route.query.d as string) || "/");
                    return;
                }
            } catch (e) {
                throw e;
            } finally {
                this.loading = false;
            }
            showError(this.$t("Das von Ihnen eingegebene Passwort ist falsch.") as string, 3000);
        },
    },
});
