import Vue from "vue";
import Vuetify from "vuetify";
import de from "vuetify/src/locale/de";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    theme: {
        themes: {
            dark: {
                primary: "#FFFFFF",
                accent: "#868686",
                secondary: "#505050",
                anchor: "#FFFFFF",
            },
            light: {
                primary: "#383838",
                accent: "#9a9a9a",
                secondary: "#575757",
                anchor: "#000000",
            },
        },
    },
    lang: {
        locales: { de },
    },
});
