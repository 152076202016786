import { reactive } from "@/util/reactive";

@reactive
class Snackbar {
    private visible = false;
    private text = "";
    private error = false;
    private timeout?: number;

    get isVisible() {
        return this.visible;
    }

    get message() {
        return this.text;
    }

    get isError() {
        return this.error;
    }

    display(message: string, timeout: number, error = false) {
        this.text = message;
        this.error = error;
        this.visible = true;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (timeout > 0) {
            this.timeout = setTimeout(() => this.hide(), timeout);
        } else {
            this.timeout = undefined;
        }
    }

    hide() {
        this.visible = false;
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
    }
}

export const snackbar = new Snackbar();
