import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface FederalState {
    readonly id: string;
}

interface FederalStateApi {
    getAll(): Promise<FederalState[]>;
}

export const federalStateApi: FederalStateApi = {
    async getAll() {
        return cloneObject((await axios.get("/api/federal-state")).data);
    },
};
