import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface Configuration {
    readonly shortCommitId: string;
    readonly buildTime: Date;
    readonly timezone: string;
}

interface ConfigurationApi {
    config(): Promise<Configuration>;
}

export const configurationApi: ConfigurationApi = {
    async config() {
        return cloneObject((await axios.get("/api/frontend-config")).data);
    },
};
