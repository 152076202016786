import "./app/errorHandling";
import i18n from "./app/i18n";
import App from "./app/pages/App.vue";
import Loader from "./app/pages/Loader.vue";
import "./app/ids";
import "./app/csrf";
import "./app/filters";
import router from "./app/router";
import vuetify from "./app/vuetify";
import { configStore } from "./store/config";
import { userSession } from "./store/userSession";
import "./store/now";
import Vue from "vue";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/900-italic.css";
import "@mdi/font/css/materialdesignicons.css";
import "flag-icons/css/flag-icons.css";

new Vue({
    vuetify,
    render: (h) => h(Loader),
}).$mount("#app");

Promise.all([configStore.initialize(), userSession.initialize()]).then(() => {
    new Vue({
        i18n,
        router,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
});
