import moment, { tz } from "moment-timezone";

export type DateTimeOutputFormat = "L" | "S";

export function availableTimeZones() {
    return tz.names();
}

export function is24hrFormat(locale: string) {
    return moment("2000-01-01T23:00:00Z")
        .tz("UTC")
        .locale(locale)
        .format("LT")
        .startsWith("23");
}

export function getFirstDayOfWeek(locale: string) {
    return moment()
        .locale(locale)
        .weekday(0)
        .day();
}

export function getDayOfWeekName(dayOfWeek: number, locale: string) {
    return moment("2000-01-02T23:00:00Z")
        .tz("UTC")
        .locale(locale)
        .add(dayOfWeek, "d")
        .format("dddd");
}

export function getMonthName(month: number, locale: string, format: DateTimeOutputFormat) {
    return moment()
        .locale(locale)
        .month(month)
        .format(format === "S" ? "MMM" : "MMMM");
}

export function getDate(date: Date, tz: string, daysOffset?: number, hoursOffset?: number, minutesOffset?: number) {
    return moment(date)
        .tz(tz)
        .add(daysOffset ? daysOffset : 0, "d")
        .add(hoursOffset ? hoursOffset : 0, "hours")
        .add(minutesOffset ? minutesOffset : 0, "minutes")
        .format()
        .substring(0, 10);
}

export function getTime(date: Date, tz: string, daysOffset?: number, hoursOffset?: number, minutesOffset?: number) {
    return moment(date)
        .tz(tz)
        .add(daysOffset ? daysOffset : 0, "d")
        .add(hoursOffset ? hoursOffset : 0, "hours")
        .add(minutesOffset ? minutesOffset : 0, "minutes")
        .format()
        .substring(11, 16);
}

export function toDateObject(tz: string, date: string, daysOffset?: number, time?: string) {
    const m = time ? moment.tz(`${date}T${time}`, tz) : moment.tz(`${date}T00:00`, tz);
    return m.add(daysOffset ? daysOffset : 0, "d").toDate();
}

export function getCurrentMonth(now: Date, tz: string) {
    return moment(now)
        .tz(tz)
        .format()
        .substring(0, 7);
}

export function formatInstant(date: Date | string, tz: string, locale: string, format: DateTimeOutputFormat) {
    return moment(date)
        .tz(tz)
        .locale(locale)
        .format(format === "S" ? "LLL" : "LLLL");
}

export function formatLocalDate(localDate: string, locale: string, format: DateTimeOutputFormat) {
    return moment(localDate)
        .locale(locale)
        .format(format === "S" ? "L" : "LL");
}

export function formatLocalTime(localTime: string, locale: string, format: DateTimeOutputFormat) {
    return moment(`2000-01-01T${localTime}`)
        .locale(locale)
        .format(format === "S" ? "LT" : "LTS");
}

export function getAge(now: Date, localDate: string, tz: string) {
    return moment(now)
        .tz(tz)
        .diff(localDate, "years");
}

export function fromNow(now: Date, localDate: string, tz: string, locale: string) {
    const currentDate = getDate(now, tz);

    if (currentDate === localDate) {
        return null;
    }

    return moment(localDate)
        .tz(tz)
        .locale(locale)
        .from(currentDate);
}

export function formatDifference(from: Date, to: Date, sign: boolean, locale?: string) {
    const diff = moment.duration(moment(to).diff(from));
    if (locale) {
        return diff.locale(locale).humanize(sign);
    }
    const hours = Math.floor(Math.abs(diff.asHours()));
    return `${sign && Math.trunc(diff.asSeconds()) < 0 ? "-" : ""}${hours > 0 ? hours + ":" : ""}${String(
        Math.abs(diff.minutes())
    ).padStart(hours > 0 ? 2 : 1, "0")}:${String(Math.abs(diff.seconds())).padStart(2, "0")}`;
}

export function getDateByDayOfWeek(week: Week, dayOfWeek: number) {
    for (let i = 0; i < 7; i++) {
        const day = moment(week.begin).add(i, "d");

        if (day.weekday() === dayOfWeek) {
            return day.format().substring(0, 10);
        }
    }
}

export function getWeek(date: Date, weeksOffsetFromCurrentWeek: number, tz: string, locale: string): Week {
    const beginOfWeek = moment(date)
        .tz(tz)
        .locale(locale)
        .add(weeksOffsetFromCurrentWeek * 7, "d")
        .startOf("week");

    return {
        weekNumber: beginOfWeek.week(),
        monday: beginOfWeek
            .clone()
            .day(1)
            .format()
            .substring(0, 10),
        begin: beginOfWeek.format().substring(0, 10),
        end: beginOfWeek
            .clone()
            .add(6, "d")
            .format()
            .substring(0, 10),
    };
}

export interface Week {
    readonly weekNumber: number;
    readonly monday: string;
    readonly begin: string;
    readonly end: string;
}

export function getDaysOfWeek(localeForFirstDay: string, localeForDayNames: string) {
    const result = [];
    const firstDayOfWeek = getFirstDayOfWeek(localeForFirstDay);
    for (let i = 0; i < 7; i++) {
        const n = (firstDayOfWeek + i) % 7;
        result.push({ value: n.toString(), text: getDayOfWeekName(n, localeForDayNames) });
    }
    return result;
}

export function getCurrentUtcYear(now: Date) {
    return moment(now).year();
}

export function getWeekDayOfDate(date: string) {
    return moment(date).weekday();
}
