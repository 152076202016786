
import Vue from "vue";

function formatNumber(v: string | number | null | undefined, locale: string, d?: string) {
    if (v === null || v === undefined) {
        return "";
    }
    v = v.toString().trim();
    let i = v.lastIndexOf(".");
    if (i < 0) {
        i = v.lastIndexOf(",");
    }
    if (i >= 0) {
        if (!d) {
            d = new Intl.NumberFormat(locale, { minimumFractionDigits: 1 }).format(0)[1];
        }
        return v.substring(0, i) + d + v.substring(i + 1);
    }
    return v;
}

function currencySymbol(currency: string, locale: string) {
    return (0)
        .toLocaleString(locale, {
            style: "currency",
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
}

export default Vue.extend({
    props: {
        value: [String, Number],
        rules: Array as () => Function[],
        suffix: {
            type: String,
            required: false,
        },
        currencySuffix: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            formattedValue: formatNumber(this.value, this.$i18n.locale),
            internalValue: formatNumber(this.value, this.$i18n.locale, "."),
        };
    },

    computed: {
        rulez(): Function[] {
            const rs = this.rules || [];
            return rs.map((r) => (v: string) => r(formatNumber(v, this.$i18n.locale, ".")));
        },

        computedSuffix(): string | undefined {
            return (
                this.suffix ||
                (this.currencySuffix ? currencySymbol(this.currencySuffix, this.$i18n.locale) : undefined)
            );
        },
    },

    methods: {
        input(v: string) {
            this.internalValue = formatNumber(v, this.$i18n.locale, ".");
            this.$emit("input", this.internalValue);
        },

        change(v: string) {
            this.internalValue = formatNumber(v, this.$i18n.locale, ".");
            this.formattedValue = (this.$refs.textField as any).lazyValue = formatNumber(v, this.$i18n.locale);
            this.$emit("change", this.internalValue);
        },
    },

    watch: {
        value() {
            const internalValue = formatNumber(this.value, this.$i18n.locale, ".");
            if (this.internalValue !== internalValue) {
                this.internalValue = internalValue;
                this.formattedValue = formatNumber(this.value, this.$i18n.locale);
            }
        },
    },
});
