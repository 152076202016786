
import { corporationApi, CorporationProcessOverview } from "@/api/corporation";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            items: [] as CorporationProcessOverview[],
            loading: true,
        };
    },

    methods: {
        async reload() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async loadItems() {
            this.items = [];
            this.loading = true;
            try {
                this.items = await corporationApi.getAllProcessOverviewsWithMissingFields();
                this.loading = false;
            } catch (e) {
                this.loading = false;
                throw e;
            }
        },

        downloadHtmlAttachment(id: string) {
            window.open(corporationApi.generateHtmlContentLink(id));
        },

        downloadXmlAttachment(id: string) {
            window.open(corporationApi.generateXmlContentLink(id));
        },

        async getCorporationAdditionalData(id: string) {
            const corporationAdditionalData = await corporationApi.getCorporationAdditionalData(id);

            alert(JSON.stringify(corporationAdditionalData, null, 2));
        },

        async refetchCorporationDetailSource(id: string) {
            alert(await corporationApi.refetchCorporationDetailSource(id));
        },
    },

    async mounted() {
        await this.loadItems();
    },
});
