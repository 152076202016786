import Login from "./pages/Login.vue";
import MainFrame from "./pages/MainFrame.vue";
import NotFound from "./pages/NotFound.vue";
import CorporationSearch from "./pages/corporation/CorporationSearch.vue";
import RegisterNumberFetchStatistics from "./pages/registernumberfetchstatistics/RegisterNumberFetchStatistics.vue";
import RegisterNumberListStatistics from "./pages/registernumberliststatistics/RegisterNumberListStatistics.vue";
import RegisterNumberProcessStatistics from "./pages/registernumberprocessstatistics/RegisterNumberProcessStatistics.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const $t = (s: string) => s;

export default new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/login",
            component: Login,
            meta: { title: $t("Login") },
            beforeEnter(to, _, next) {
                if (userSession.authenticated) {
                    next((to.query.d as string) || "/");
                } else {
                    next();
                }
            },
        },
        {
            path: "/",
            component: MainFrame,
            children: [
                { path: "", component: CorporationSearch },
                { path: "registernumberliststatistics", component: RegisterNumberListStatistics },
                { path: "registernumberfetchstatistics", component: RegisterNumberFetchStatistics },
                { path: "registernumberprocessstatistics", component: RegisterNumberProcessStatistics },
                { path: "404", component: NotFound },
                { path: "*", component: NotFound },
            ],
            beforeEnter(to, _, next) {
                if (userSession.authenticated) {
                    next();
                } else {
                    next(`/login?d=${to.fullPath}`);
                }
            },
        },
    ],
});
