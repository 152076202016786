
import Snackbar from "./Snackbar.vue";
import GlobalDialog from "@/app/pages/GlobalDialog.vue";
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    watch: {
        "userSession.locale"(locale: string) {
            this.$i18n.locale = this.$vuetify.lang.current = locale;
        },

        "userSession.darkmode"(value: boolean) {
            this.$vuetify.theme.dark = value;
        },
    },

    beforeMount() {
        this.$i18n.locale = this.$vuetify.lang.current = userSession.locale;
        this.$vuetify.theme.dark = userSession.darkmode;
    },

    components: {
        Snackbar,
        GlobalDialog,
    },
});
