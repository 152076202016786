
import { corporationApi, CorporationFetchStatus, CorporationOverview } from "@/api/corporation";
import { RegisterCourt, registerCourtApi } from "@/api/registercourt";
import Vue from "vue";

export default Vue.extend({
    props: {
        corporationFetchStatus: {
            type: String as () => CorporationFetchStatus,
        },
    },

    data() {
        return {
            registerCourts: [] as RegisterCourt[],
            items: [] as CorporationOverview[],
            loading: true,
        };
    },

    computed: {
        CorporationFetchStatus() {
            return CorporationFetchStatus;
        },
    },

    methods: {
        findRegisterCourtNameById(registerCourtId: string) {
            return this.registerCourts.find((item) => item.id === registerCourtId)!.name;
        },

        async reload() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async loadItems() {
            this.items = [];
            this.loading = true;
            try {
                this.items = await corporationApi.getByLastFetchStatus(this.corporationFetchStatus);
                this.loading = false;
            } catch (e) {
                this.loading = false;
                throw e;
            }
        },

        async deleteWhereLastFetchStatusIsZeroResults() {
            this.loading = true;
            try {
                await corporationApi.deleteWhereLastFetchStatusIsZeroResults();
                await this.loadItems();
                this.loading = false;
            } catch (e) {
                this.loading = false;
                throw e;
            }
        },
    },

    async mounted() {
        this.registerCourts = await registerCourtApi.getAll();
        await this.loadItems();
    },
});
