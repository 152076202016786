/* eslint-disable prettier/prettier */
export default {
    "enum": {
        "CorporationSearchOrder": {
            "PUBLISHED_DESC": "Veröffentlichungsdatum (absteigend)",
            "PUBLISHED_ASC": "Veröffentlichungsdatum (aufsteigend)",
        },
        "CorporationStatusSerdar": {
            "UNKNOWN": "Unbekannt",
        },
        "CorporationStatusSoner": {
            "UNKNOWN": "Unbekannt",
            "REJECTED": "Abgelehnt",
            "POTENTIAL": "Potenzial",
            "CRM": "CRM",
            "FL_POTENTIAL": "FL Potenzial",
            "FL_CRM": "FL CRM",
        },
        "CorporationStatusTaner": {
            "UNKNOWN": "Unbekannt",
            "USELESS": "Unbrauchbar",
            "GOOD_IDEA": "Gut",
            "EXCELLENT_IDEA": "Exzellent",
            "CHECK_LATER": "Zurückgestellt",
        },
        "CorporationMemberType": {
            "DIRECTOR": "Geschäftsführer/in",
            "PROXY": "Prokurist/in",
            "LIQUIDATOR": "Liquidator/in",
        },
        "FederalStateId": {
            "be": "Berlin",
            "br": "Brandenburg",
            "bw": "Baden-Württemberg",
            "by": "Bayern",
            "hb": "Bremen",
            "he": "Hessen",
            "hh": "Hamburg",
            "mv": "Mecklenburg-Vorpommern",
            "ni": "Niedersachsen",
            "nw": "Nordrhein-Westfalen",
            "rp": "Rheinland-Pfalz",
            "sh": "Schleswig-Holstein",
            "sl": "Saarland",
            "sn": "Sachsen",
            "st": "Sachsen-Anhalt",
            "th": "Thüringen",
        },
    },
};
