
import Vue from "vue";

export default Vue.extend({
    props: {
        value: [String as () => string | null, Array as () => string[]],
        multiple: {
            type: Boolean,
            default: false,
        },
        sortByText: {
            type: Boolean,
            default: false,
        },
        values: Object,
        text: Function,
    },

    computed: {
        items(): { value: string; text: string }[] {
            const result = Object.keys(this.values).map((k) => ({
                value: k,
                text: this.text(k),
            }));

            if (this.sortByText) {
                result.sort((a, b) => (a.text as string).localeCompare(b.text as string, this.$i18n.locale));
            }

            return result;
        },
    },
});
