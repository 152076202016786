import axios from "axios";

interface AuthenticationApi {
    login(password: string): Promise<string>;
    logout(): Promise<void>;
    authenticated(): Promise<string>;
}

export const authenticationApi: AuthenticationApi = {
    async login(password) {
        const formData = new FormData();
        if (password) {
            formData.append("password", password);
        }
        return (await axios.post("/api/authentication/_login", formData)).data;
    },

    async logout() {
        await axios.post("/api/authentication/_logout");
    },

    async authenticated() {
        return (await axios.get("/api/authentication")).data;
    },
};
