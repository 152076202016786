
import { CorporationFetchStatus } from "@/api/corporation";
import RegisterNumberFetchStatisticsCard from "@/app/pages/registernumberfetchstatistics/RegisterNumberFetchStatisticsCard.vue";
import Vue from "vue";

export default Vue.extend({
    computed: {
        CorporationFetchStatus() {
            return CorporationFetchStatus;
        },
    },
    components: { RegisterNumberFetchStatisticsCard },
});
