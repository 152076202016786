import Vue from "vue";

let id = new Date().getTime();

Vue.mixin({
    methods: {
        $id() {
            return `id-${id++}`;
        },
    },
});
