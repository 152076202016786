import { Configuration, configurationApi } from "@/api/config";
import { reactive } from "@/util/reactive";

@reactive
class ConfigStore {
    private config: Configuration | null = null;

    get configuration() {
        return this.config!;
    }

    async initialize() {
        this.config = await configurationApi.config();
    }
}

export const configStore = new ConfigStore();
