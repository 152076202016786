
import { userSession } from "@/store/userSession";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            drawer: null as boolean | null,
        };
    },

    methods: {
        async logout() {
            await userSession.logout();
            await this.$router.push("/login");
        },

        toggleDrawer() {
            this.drawer = !this.drawer;
        },
    },
});
