import { authenticationApi } from "@/api/authentication";
import { Unauthorized } from "@/api/errors";
import { reactive } from "@/util/reactive";

@reactive
export class UserSession {
    private csrfToken_ = null as string | null;
    private readonly locale_ = "de";
    private readonly darkmode_ = false;

    get authenticated() {
        return !!this.csrfToken_;
    }

    get csrfToken() {
        return this.csrfToken_;
    }

    get locale() {
        return this.locale_;
    }

    get darkmode() {
        return this.darkmode_;
    }

    async initialize() {
        try {
            this.csrfToken_ = await authenticationApi.authenticated();
        } catch (e) {
            if (!(e instanceof Unauthorized)) {
                throw e;
            }
        }
    }

    async login(password: string) {
        try {
            this.csrfToken_ = await authenticationApi.login(password);
        } catch (e) {
            if (!(e instanceof Unauthorized)) {
                throw e;
            }
        }
        return this.authenticated;
    }

    async logout() {
        try {
            await authenticationApi.logout();
        } catch (e) {
            // ignore
        }
        this.csrfToken_ = null;
    }
}

export const userSession = new UserSession();
