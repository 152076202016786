
import DataTablePagination from "./DataTablePagination.vue";
import Vue from "vue";

interface Paging {
    page: number;
    readonly pageSize: number;
    readonly totalSize: number;
    readonly maxTotalSize: number;
    readonly maxPage: number;
}

export default Vue.extend({
    props: {
        dense: {
            type: Boolean,
            default: false,
        },
        hideTopPagination: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        noItemsText: {
            type: String,
        },
        paging: {
            type: Object as () => Paging | undefined,
            required: false,
        },
        search: {
            type: String as () => string | undefined,
            required: false,
        },
        searchFilter: {
            type: Function as () => Function | undefined,
            required: false,
        },
    },

    methods: {
        filter(_: unknown, search: string, item: unknown) {
            return this.searchFilter!(search, item);
        },
    },

    components: {
        DataTablePagination,
    },
});
