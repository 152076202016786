import { reactive } from "@/util/reactive";

@reactive
class Now {
    public value = new Date();
}

const internalNow = new Now();
setInterval(() => (internalNow.value = new Date()), 1000);

export function now() {
    return internalNow.value;
}
